import PropTypes from 'prop-types';

const propTypes = {
  contentState: PropTypes.shape({
    getEntity: PropTypes.func,
  }).isRequired,
  entityKey: PropTypes.string,
  offsetKey: PropTypes.string.isRequired,
  editorEnabled: PropTypes.bool,
  children: PropTypes.node,
};
const defaultProps = {
  entityKey: '',
  editorEnabled: true,
  children: null,
};

function LinkDecorator(props) {
  const { url, targetBlank } = props.contentState.getEntity(props.entityKey).getData();
  const conditionalAnchorProps = {};

  if (targetBlank) {
    Object.assign(conditionalAnchorProps, {
      target: '_blank',
      rel: 'noreferrer noopener',
    });
  }

  return (
    <a
      contentEditable={props.editorEnabled}
      suppressContentEditableWarning
      data-offset-key={props.offsetKey}
      className="editor__link"
      href={url}
      title={url}
      {...conditionalAnchorProps}
    >
      {props.children}
    </a>
  );
}

LinkDecorator.propTypes = propTypes;
LinkDecorator.defaultProps = defaultProps;

export default LinkDecorator;
