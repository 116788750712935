import { CompositeDecorator } from 'draft-js';
import createTypeStrategy from '../utils/createTypeStrategy';
import LinkDecorator from '../components/decorators/LinkDecorator';
import SearchHighlightDecorator from '../components/decorators/SearchHighlightDecorator';
import removeDiacritics from '@/stories/utils/seo/removeDiacritics';

const findWithRegex = (regex, contentBlock, callback) => {
  const text = removeDiacritics(contentBlock.getText());
  let matchArr;
  let start;
  let end;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    callback(start, end);
  }
};

export default function getDefaultDecorator(
  { highlightStrings, highlightBlocks, editorEnabled } = {
    highlightStrings: null,
    highlightBlocks: null,
    editorEnabled: true,
  },
) {
  const decorators = [];

  if (highlightStrings) {
    // Remove special characters
    let hightlightStringRegEx = removeDiacritics(
      highlightStrings.map((string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'),
    );
    // parse html entities
    const textarea = document.createElement('textarea');
    textarea.innerHTML = hightlightStringRegEx;
    hightlightStringRegEx = textarea.value;
    const regex = new RegExp(hightlightStringRegEx, 'g');
    const highlightStringsDecorator = {
      strategy: (contentBlock, callback) => {
        if (hightlightStringRegEx !== '') {
          findWithRegex(regex, contentBlock, callback);
        }
      },
      component: SearchHighlightDecorator,
    };
    decorators.push(highlightStringsDecorator);
  }
  if (highlightBlocks) {
    const highlightStringsDecorator = {
      strategy: (contentBlock, callback) => {
        const blockKey = contentBlock.getKey();
        if (highlightBlocks.find((highlightBlock) => highlightBlock === blockKey)) {
          callback(0, contentBlock.getLength());
        }
      },
      component: SearchHighlightDecorator,
    };
    decorators.push(highlightStringsDecorator);
  }

  decorators.push({
    strategy: createTypeStrategy('LINK'),
    component: LinkDecorator,
    props: {
      editorEnabled,
    },
  });

  return new CompositeDecorator(decorators);
}
