import PropTypes from 'prop-types';

const propTypes = {
  offsetKey: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})),
};
const defaultProps = {
  children: [],
};

function SearchHighlightDecorator({ offsetKey, children }) {
  return (
    <span data-offset-key={offsetKey} className="search-highlight">
      {children}
    </span>
  );
}

SearchHighlightDecorator.propTypes = propTypes;
SearchHighlightDecorator.defaultProps = defaultProps;

export default SearchHighlightDecorator;
